/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// require("prism-themes/themes/prism-hopscotch.css")

require("prism-themes/themes/prism-shades-of-purple.css")
